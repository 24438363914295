import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useSelector } from "react-redux";
import IMAGES from "../../img/image";
import "../Footer/Footer.css";

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector((state) => state.user);
  return (
    <React.Fragment>
      <div className="container-fluid footer-container">
        <div className="wa-icon-cont">
          <Link target="_blank" to="https://wa.me/919856944637">
            <WhatsAppIcon className="icon" />
          </Link>
        </div>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-3 col-lg-3 mb-4">
            <div className="footer-logo">
              <b>
                EVIL<span className="ffca00">STORE</span>
              </b>
            </div>
            <span>
              <small>
                Welcome to Evil Store! Get in-game currencies for Mobile
                Legends, BGMI, PUBG, Genshin Impact, and more with fast,
                reliable service and great customer support.
              </small>
            </span>
            <p className="m-0 mt-3 fw-bold">SUPPORT</p>
            <span>daniel.tongbram@outlook.com</span>
            <br />
            <span>+91 9856944637</span>
            <hr />
            <div className="social">
              <Link
                target="_blank"
                to="https://www.instagram.com/theevilstore.in"
              >
                <InstagramIcon className="icon" />
              </Link>
              <Link target="_blank" to="https://wa.me/919856944637">
                <WhatsAppIcon className="icon" />
              </Link>
              <Link to="mailto:daniel.tongbram@outlook.com">
                <EmailIcon className="icon" />
              </Link>
            </div>
          </div>
          <div className="px-lg-5 quick-links col-6 col-sm-6 col-md-3 col-lg-3 mb-4">
            <h6>Quick Links</h6>
            <hr />
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              {!user && (
                <>
                  <li>
                    <Link to="/login">Login</Link>
                  </li>
                  <li>
                    <Link to="/register">Register</Link>
                  </li>
                </>
              )}
              {user && (
                <>
                  <li>
                    <Link to="/wallet">Wallet</Link>
                  </li>
                  <li>
                    <Link to="/orders">Orders</Link>
                  </li>
                  <li>
                    <Link to="/user-dashboard">Dashboard</Link>
                  </li>
                </>
              )}
              <li>
                <Link to="/support">Customer Support</Link>
              </li>
            </ul>
          </div>
          <div className="quick-links col-6 col-sm-6 col-md-3 col-lg-3 mb-4">
            <h6>Important Pages</h6>
            <hr />
            <ul>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms">Terms & Conditions</Link>
              </li>
              <li>
                <Link to="/refund-policy">Refund Policy</Link>
              </li>
            </ul>
          </div>
          <div className="quick-links col-12 col-sm-12 col-md-3 col-lg-3 mb-4">
            <h6>Payment Modes</h6>
            <hr />
            <div className="payment-channels">
              <img src={IMAGES.phonepe} alt="" />
              <img src={IMAGES.gpay} alt="" />
              <img src={IMAGES.upi} alt="" />
            </div>
          </div>
          <hr />
          <span className="">
            <small>All Rights Reserved © 2024 | EVIL STORE</small>
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;
